import { Component, OnInit, HostListener, OnDestroy } from '@angular/core'
import { IdleService } from '@shared/services/idle.service'
import { Subscription } from 'rxjs'
import { KioskServerService } from "@shared/services/kiosk-server.service"
import { KioskService } from "@shared/services/kiosk.service"
import { AuthenticationService } from 'src/app/modules/authentication-module/authentication.service'

const KIOSK_ID_KEY = 'kiosk_id'
const KIOSK_JDE_KEY = 'kiosk_jde'
const KIOSK_TOKEN_KEY = 'kiosk_token'
const KIOSK_ENVIRONMENT_KEY = 'kiosk_environment'
const KIOSK_VERSION_KEY = 'kiosk_version'
const BASE_URL_KEY = 'base_url'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  public offset: number
  public transform: string
  public userIdle: boolean
  public title: string
  private subscription: Subscription
  private commandInterval: any
  private statusInterval: any
  private tokenInterval: any

  public constructor(
    private idleService: IdleService,
    private kioskService: KioskService,
    private kioskServerService: KioskServerService,
    private authService: AuthenticationService,
  ) {
    this.offset = 0
    this.userIdle = false
    this.title = 'rug-doctor-client-angular'
  }

  public ngOnInit() {
    this.scale()
    this.idleService.idle.subscribe(isIdle => {
      this.userIdle = isIdle
    })
    this.idleService.start()

    localStorage.setItem(BASE_URL_KEY, "https://api.rapidrent.com/")
    this.kioskServerService.getKioskConfig().subscribe(
      next => {
        localStorage.setItem(KIOSK_JDE_KEY, next.kioskJde.toString())
        localStorage.setItem(KIOSK_ID_KEY, next.kioskId.toString())
        localStorage.setItem(KIOSK_TOKEN_KEY, next.token.toString())
        localStorage.setItem(KIOSK_ENVIRONMENT_KEY, next.kioskEnvironment.toString())
        localStorage.setItem(KIOSK_VERSION_KEY, next.kioskVersion.toString())

        switch (next.kioskEnvironment.toString()) {
          case 'TESTING':
          case 'DEVELOPMENT':
            localStorage.setItem(BASE_URL_KEY, "https://dev.api.rapidrent.com/")
            break
          case 'STAGING':
            localStorage.setItem(BASE_URL_KEY, "https://staging.api.rapidrent.com/")
            break
          case 'UAT':
            localStorage.setItem(BASE_URL_KEY, "https://uat.api.rapidrent.com/")
            break
          case 'UAT':
          default:
            localStorage.setItem(BASE_URL_KEY, "https://api.rapidrent.com/")
            break
        }
      },
      error => {
        console.log(error)
      }
    )

    if (KIOSK_ID_KEY in localStorage) {
      this.authService.registerKioskId(localStorage.getItem(KIOSK_ID_KEY)).subscribe(() => {
        // Token refreshed
      })
    }

    let _this = this
    this.commandInterval = setInterval(function () {
      _this.kioskService.getKioskCommands().subscribe(commands => {
        if (commands && commands.length > 0) {
          commands.forEach(command => {
            switch (command.name) {
              case 'open-bay':
                _this.kioskServerService.openBay(command.params['bayId'])
                break
              case 'close-bay':
                _this.kioskServerService.closeBay(command.params['bayId'])
                break
            }
          })
        }
      })
    }, 15000) // Every 15 seconds

    this.statusInterval = setInterval(function () {
      let status = _this.userIdle ? 'idle' : 'active'

      _this.kioskService.sendKioskStatus(status).subscribe(message => {
        // Status Sent
        console.log(message)
      })
    }, 54000 * 1000) // Every 12 hours

    this.tokenInterval = setInterval(function () {
      if (KIOSK_ID_KEY in localStorage) {
        _this.authService.registerKioskId(localStorage.getItem(KIOSK_ID_KEY)).subscribe(() => {
          // Token refreshed
        })
      }
      console.log('hey')
    }, 604800 * 1000) // Every 7 days

  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe()
    clearInterval(this.commandInterval)
    clearInterval(this.statusInterval)
    clearInterval(this.tokenInterval)
  }

  @HostListener('window:resize')
  public onResize() {
    this.scale()
  }

  private scale() {
    const height: number = window.innerHeight
    const width: number = window.innerWidth
    const scaleFactor: number = height / 1920
    this.transform = 'scale(' + scaleFactor + ')'
    this.offset = (width - 1080 * scaleFactor) / 2
  }
}
