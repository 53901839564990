import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { HomeComponent } from './screens/home/home.component'
import { SplashComponent } from './screens/splash/splash.component'
import { PaymentComponent } from './screens/payment/payment.component'
import { MachinesComponent } from './screens/rent/machines/machines.component'
import { AttachmentsComponent } from './screens/rent/attachments/attachments.component'
import { SolutionsComponent } from './screens/rent/solutions/solutions.component'
import { BundlesComponent } from './screens/rent/bundles/bundles.component'
import { SummaryComponent } from './screens/rent/summary/summary.component'
import { CouponComponent } from './screens/rent/coupon/coupon.component'
import { AgreementComponent } from './screens/rent/agreement/agreement.component'
import { HowToRentComponent } from './screens/tutorial/how-to-rent/how-to-rent.component'
import { HowToUseComponent } from './screens/tutorial/how-to-use/how-to-use.component'
import { BaysComponent } from './screens/bays/bays.component'
import { ReturnComponent } from './screens/return/return.component'
import { AdminComponent } from './screens/admin/admin.component'
import { LoginComponent } from './screens/admin/login/login.component'
import { AuthGuard } from './modules/authentication-module/auth.guard'
import { ScreenSaverComponent } from './screens/idle/screen-saver/screen-saver.component'
import { ReservationComponent } from './screens/reservation/reservation.component'
import { DisconnectedComponent } from './screens/disconnected/disconnected.component'

const routes: Routes = [
  {
    path: '',
    component: SplashComponent,
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'rent/machine',
    component: MachinesComponent
  },
  {
    path: 'rent/attachment',
    component: AttachmentsComponent
  },
  {
    path: 'rent/solution',
    component: SolutionsComponent
  },
  {
    path: 'rent/bundle/:index',
    component: BundlesComponent
  },
  {
    path: 'rent/summary',
    component: SummaryComponent
  },
  {
    path: 'rent/coupon',
    component: CouponComponent
  },
  {
    path: 'rent/agreement',
    component: AgreementComponent
  },
  {
    path: 'return/:step',
    component: ReturnComponent
  },
  {
    path: 'payment/:step',
    component: PaymentComponent
  },
  {
    path: 'reservation',
    component: ReservationComponent
  },
  {
    path: 'bays',
    component: BaysComponent
  },
  {
    path: 'screen-saver',
    component: ScreenSaverComponent
  },
  {
    path: 'tutorial/how-to-rent',
    component: HowToRentComponent
  },
  {
    path: 'tutorial/how-to-use',
    component: HowToUseComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'disconnected',
    component: DisconnectedComponent
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
