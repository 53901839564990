import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Kiosk } from 'src/app/domain/kiosk.model'
import { KioskCommand } from 'src/app/domain/kiosk-commands.model'
import { environment } from 'src/environments/environment'
import { Deserialize } from 'cerialize'
import { AuthenticationService } from 'src/app/modules/authentication-module/authentication.service'

const BASE_URL_KEY = 'base_url'

@Injectable({
  providedIn: 'root'
})
export class KioskService {

  public kiosk: Kiosk

  private baseUrl: string

  public constructor(
    private http: HttpClient,
    private auth: AuthenticationService
  ) {
    this.baseUrl = localStorage.getItem(BASE_URL_KEY)
  }

  public getKioskInfo(): Observable<Kiosk> {
    return this.http.get(this.baseUrl + 'kiosks/' + this.auth.kioskId).pipe(
      map((kiosk: Kiosk) => {
        const kioskSelected = Deserialize(kiosk, Kiosk)
        this.kiosk = kioskSelected

        return kioskSelected
      }))
  }

  public getKioskCommands(): Observable<KioskCommand[]> {
    return this.http.get(this.baseUrl + 'kiosks/' + this.auth.kioskId + '/commands', { params: { type: 'client' } }).pipe(
      map((command: KioskCommand[]) => {
        return Deserialize(command, KioskCommand)
      }))
  }

  public sendKioskStatus(status: string): Observable<string> {
    return this.http.post(this.baseUrl + 'kiosks/' + this.auth.kioskId + '/status', { params: { status: status } }).pipe(
      map((message: string) => {
        return message
      }))
  }
}
