import { Injectable } from '@angular/core'
import { UserIdleService } from 'angular-user-idle'
import { RoutingService } from '.'
import { Observable, BehaviorSubject } from 'rxjs'
import { KioskServerService } from './kiosk-server.service'
import { AdminService } from './admin.service'
import { Bay } from 'src/app/domain/bay.model'

@Injectable({
  providedIn: 'root'
})
export class IdleService {

  private isUserIdle: BehaviorSubject<boolean>
  public countdown: number

  public get idle(): Observable<boolean> {
    return this.isUserIdle.asObservable()
  }

  public constructor(
    private userIdle: UserIdleService,
    private adminService: AdminService,
    private routingService: RoutingService,
    private kioskServerService: KioskServerService
  ) {
    this.isUserIdle = new BehaviorSubject<boolean>(false)
  }

  public start(): void {
    this.userIdle.startWatching()
    this.userIdle.onTimerStart().subscribe(count => {

      if (count) {
        this.countdown = count
      }

      if (
        this.routingService.currentRoute !== '/' &&
        this.routingService.currentRoute !== '/screen-saver' &&
        this.routingService.currentRoute !== '/user-idle'
      ) {
        this.isUserIdle.next(true)
      } else {
        this.isUserIdle.next(false)
      }
    })
    this.userIdle.onTimeout().subscribe(() => {
      this.userIdle.resetTimer()
      this.isUserIdle.next(false)

      if (
        this.routingService.currentRoute !== '/' &&
        this.routingService.currentRoute !== '/screen-saver' &&
        this.routingService.currentRoute !== '/user-idle'
      ) {
        this.adminService.abandonRentalProcess()
        this.adminService.getBays().subscribe((bays: Bay[]) => {
          this.kioskServerService.closeAll(bays.length).subscribe(() => console.log('Idle: Locked all bays'))
        })
        this.routingService.navigateToSplashScreen()
      } else if (this.routingService.currentRoute === '/') {
        this.routingService.navigateToScreenSaver()
      }
    })
  }

  public restart(): void {
    this.userIdle.resetTimer()
    this.isUserIdle.next(false)
  }
}
