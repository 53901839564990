import * as moment from 'moment'
import { Component, OnInit } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { RoutingService } from '@services'
import { KioskServerService } from '@shared/services/kiosk-server.service'
import { RentalService } from '@shared/services/rental.service'
import { BaysService } from '@shared/stores/bays.service'
import { Reservation } from 'src/app/domain/reservation.model'
import { BayTransactionType } from 'src/app/domain/bay-transaction.model'
import { KeyboardLayout } from 'src/app/components/keyboard/keyboard.component'
import { InputStep } from 'src/app/domain/steps/input-step.model'
import { UtilService } from '@shared/services/util.service'
import { Card } from 'src/app/domain/card.model'

const HOME = 'home'
const CARD = 'card'
const NAME = 'name'
const SERIAL = 'serial'
const OPTIONS = 'options'
const CONFIRM = 'confirm'
const FINISH = 'finish'
const ERROR = 'error'
const STEPS: { [id: string]: InputStep } = {
  options: {
    title: 'RETURN.TITLES.OPTIONS',
    next: OPTIONS,
    back: HOME,
    input: false,
  },
  card: {
    title: 'RETURN.TITLES.CARD',
    next: CONFIRM,
    back: OPTIONS,
    input: false,
  },
  name: {
    title: 'RETURN.TITLES.NAME',
    titleLong: true,
    next: CONFIRM,
    back: OPTIONS,
    input: true,
    placeholder: 'RETURN.PLACEHOLDERS.NAME',
    validation: [Validators.required],
  },
  serial: {
    title: 'RETURN.TITLES.SERIAL',
    titleLong: true,
    next: CONFIRM,
    back: OPTIONS,
    input: true,
    placeholder: 'RETURN.PLACEHOLDERS.SERIAL',
    validation: [Validators.required, Validators.pattern(/^\d+$/), Validators.minLength(4), Validators.maxLength(4)],
    layout: KeyboardLayout.Numeric
  },
  error: {
    title: 'RETURN.TITLES.ERROR',
    message: 'RETURN.MESSAGES.ERROR',
    next: OPTIONS,
    back: OPTIONS,
    input: false,
    nextText: 'RETURN.MESSAGES.ERROR'
  },
  confirm: {
    title: 'RETURN.TITLES.CONFIRM',
    next: FINISH,
    back: OPTIONS,
    input: false,
    nextText: 'GLOBAL.CONTINUE'
  }
}

@Component({
  selector: 'app-return',
  templateUrl: './return.component.html',
  styleUrls: ['./return.component.scss']
})
export class ReturnComponent implements OnInit {

  public reservationIndex: number
  public currentStep: string
  public cardOwnerName: string
  public input: FormControl
  public reservations: Reservation[]
  public get step(): any { return STEPS[this.currentStep] }
  public constructor(
    private route: ActivatedRoute,
    private baysService: BaysService,
    private rentalService: RentalService,
    private kioskServerService: KioskServerService,
    public utilService: UtilService,
    public routingService: RoutingService
  ) {
    this.currentStep = OPTIONS
    this.input = new FormControl('', [Validators.required])
  }

  public ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.currentStep = params.step
      this.input = new FormControl('', this.step.validation)
    })
  }

  public onStepChange(step: string): void {
    if (!this.step.validation || this.input.valid) {
      if (step === FINISH) {
        this.baysService.createTransaction(BayTransactionType.Return, this.reservations[this.reservationIndex])
        this.routingService.navigateToBays()
      } else {
        if (this.currentStep === NAME) {
          this.findReservation({ name: this.input.value })
        }
        if (this.currentStep === SERIAL) {
          this.findReservation({ serial: this.input.value })
        }
        if (this.currentStep === CARD) {
          this.onCardRead()
        } else {
          this.routingService.navigateToReturn(step)
        }
      }
    }
  }

  private onCardRead(): void {
    this.kioskServerService.readCard().subscribe(result => {
      this.onCardReadComplete(result)
    }, error => { console.error(error) })
  }

  public onCardReadComplete(card: Card): void {
    this.cardOwnerName = this.utilService.formatName(card.firstName, card.lastName)
    this.findReservation({ last4Digits: card.last4Digits })
  }

  public onBack(): void {
    if (this.currentStep === OPTIONS) {
      this.routingService.navigateToSplashScreen()
    } else {
      this.routingService.navigateToReturn(OPTIONS)
    }
  }

  private findReservation(params): void {
    this.rentalService.find({
      machine_serial_number: params.serial,
      renter_name: params.name,
      credit_card_last_4_digits: params.last4Digits,
      state: 'picked_up'
    }).subscribe((reservations: Reservation[]) => {
      if (reservations.length > 0) {
        this.reservationIndex = 0
        this.reservations = reservations
        this.routingService.navigateToReturn(CONFIRM)
      } else {
        this.routingService.navigateToReturn(ERROR)
      }
    }, error => {
      console.error(error)
      this.routingService.navigateToReturn(ERROR)
    })
  }

  public onSelectReservation(index: number): void {
    this.reservationIndex = index
  }

  public formatDate(reservation: Reservation): string {
    return moment(reservation.startDate).format('MM/DD/YYYY')
  }

  public formatTime(reservation: Reservation): string {
    return moment(reservation.startDate).format('h:mm A')
  }
}
