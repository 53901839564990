import { Injectable } from '@angular/core'
import { environment } from '@environment/environment'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { Deserialize } from 'cerialize'
import { Observable } from 'rxjs'
import { AuthenticationService } from 'src/app/modules/authentication-module/authentication.service'
import { Coupon } from 'src/app/domain/coupon.model'

const BASE_URL_KEY = 'base_url'

@Injectable({
  providedIn: 'root'
})
export class CouponService {

  private baseUrl: string

  public constructor(
    private http: HttpClient,
    private auth: AuthenticationService
  ) {
    this.baseUrl = localStorage.getItem(BASE_URL_KEY)
  }

  public getCouponsByCode(code: string): Observable<Coupon[]> {
    return this.http.get(this.baseUrl + 'coupons?coupon_code=' + code, { headers: this.auth.kioskAuthHeader }).pipe(
      map((coupon: Coupon) => {
        return Deserialize(coupon, Coupon)
      }
      ))
  }
}
