import { Injectable } from '@angular/core'
import { Location } from '@angular/common'
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  constructor(
    private router: Router,
    private location: Location
  ) { }

  public get currentRoute(): string {
    return this.router.url
  }

  public navigateToSplashScreen(): void {
    this.router.navigate(['/'])
  }

  public navigateToScreenSaver(): void {
    this.router.navigate(['/screen-saver'])
  }

  public navigateToHome(): void {
    this.router.navigate(['/home'])
  }

  public navigateToLogin(): void {
    this.router.navigate(['/login'])
  }

  public navigateToRentSelectHours(): void {
    this.router.navigate(['/rent/select-time-range'])
  }

  public navigateToRent(step?: string): void {
    this.router.navigate(['/rent/' + (step || 'machine')])
  }

  public navigateToBundle(bundleIndex: number): void {
    this.router.navigate(['/rent/bundle/' + bundleIndex])
  }

  public navigateToReturn(step?: string): void {
    this.router.navigate(['/return/' + (step || 'options')])
  }

  public navigateToPayment(step?: string): void {
    this.router.navigate(['/payment/' + (step || 'creditCard')])
  }

  public navigateToReservation(): void {
    this.router.navigate(['/reservation'])
  }

  public navigateToBays(): void {
    this.router.navigate(['/bays/'])
  }

  public navigateToHowToRentScreen(): void {
    this.router.navigate(['/tutorial/how-to-rent'])
  }

  public navigateToHowToUseScreen(): void {
    this.router.navigate(['/tutorial/how-to-use'])
  }

  public navigateToAdmin(): void {
    this.router.navigate(['/admin'])
  }

  public navigateToDisconnected(): void {
    this.router.navigate(['/disconnected'])
  }

  public navigateBack(): void {
    this.location.back()
  }
}
