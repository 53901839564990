import { Component, OnInit } from '@angular/core';
import { RoutingService } from '@shared/services';
import { CartService } from '@shared/stores/cart.service';
import { StockService } from '@shared/stores/stock.service';
import { Bundle } from 'src/app/domain/bundle.model';
import { RentalProduct } from 'src/app/domain/rental-product';
import { PresentationItem } from 'src/app/domain/presentation-item.model';
import { ProductGroup, ProductType } from 'src/app/domain/product-type.model';
import { ProductService } from '@shared/services/product.service'
@Component({
    selector: 'app-machines',
    templateUrl: './machines.component.html',
    styleUrls: ['./machines.component.scss']
})
export class MachinesComponent implements OnInit {
    public loading = false;
    public kioskJde: string;
    public get progress(): number { return this.cartService.progress; }
    public get hasGroup(): boolean { return this.cartService.hasGroup(ProductGroup.Machines); }
    public get cartEmpty(): boolean { return this.cartService.isEmpty; }
    public get cartItems(): PresentationItem <RentalProduct>[] {
        return (this.cartService.items) ? this.cartService.items.map(item => new PresentationItem(item)) : [];
    }
    public get items(): PresentationItem <ProductType>[] {
      let items = (this.stockService.machines) ? this.stockService.machines.map(item => new PresentationItem(item)) : [];
      this.itemList = items
      // if(this.itemList.length > 0){
      //   let item = this.itemList[0]
      //   let itemId= item?.id ? item.id : null;
      //   if(itemId){
      //     this.productService.getRelatedSolutions(itemId).subscribe(productType => {
      //       this.solutionList = productType.map(item => new PresentationItem(item));
      //     })
      //   }
      // }
        return items;
    }
    // public get bundles(): PresentationItem <Bundle>[] {
    //     return (this.stockService.bundles) ? this.stockService.bundles.map(item => new PresentationItem(item)) : [];
    // }
    public itemList = [];
    public solutionList = [];

    public setCurrentSlideItem = (args: any): void => {
        if(this.itemList.length > 0){
          let item = this.itemList[args]
          let itemId= item.id
          this.productService.getRelatedSolutions(itemId).subscribe(productType => {
            this.solutionList = productType.map(item => new PresentationItem(item));
          })
        }
      }
    public constructor(
        private cartService: CartService,
        private stockService: StockService,
        private routingService: RoutingService,
        private productService: ProductService,
    ) { }

    public ngOnInit(): void {
      this.kioskJde = localStorage.getItem('kiosk_jde');
        if (this.cartEmpty && !this.cartService.rental.id) {
          this.loading = true;
            this.stockService.restock().subscribe( next => {
              // Sets initial recommend recommended products
              let items = (this.stockService.machines) ? this.stockService.machines.map(item => new PresentationItem(item)) : [];
              if(items.length > 0){
                let item = items[0]
                let itemId= item?.id ? item.id : null;
                if(itemId){
                  this.productService.getRelatedSolutions(itemId).subscribe(productType => {
                    this.solutionList = productType.map(item => new PresentationItem(item));
                  })
                }
              }
              //
                this.loading = false;
            },
            error => {
              this.loading = false;
            });
        }
    }

    public onRemove(index: number): void {
        this.cartService.removeAtIndex(index);
    }

    public onBack(): void {
        this.routingService.navigateToHome();
    }

    public onNext(): void {
        if (this.hasGroup && this.stockService.attachments.length > 0) {
            this.routingService.navigateToRent(ProductGroup.Attachments);
        } else {
            this.routingService.navigateToRent(ProductGroup.Solutions);
        }
    }
    // public onSelectBundle(index: number): void {
    //     this.routingService.navigateToBundle(index);
    // }
}
