import { Component, Output, EventEmitter } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { AdminService } from '@shared/services/admin.service'
import { Kiosk } from 'src/app/domain/kiosk.model'
import { AuthenticationService } from 'src/app/modules/authentication-module/authentication.service'
import { KioskServerService } from '@shared/services/kiosk-server.service'
import { environment } from '@environment/environment'

const ERROR_MESSAGE = 'Cannot find kiosk with this JDE.'

const KIOSK_JDE_KEY = 'kiosk_jde'
const KIOSK_ID_KEY = 'kiosk_id'
const KIOSK_TOKEN_KEY = 'kiosk_token'

@Component({
  selector: 'app-update-kiosk-jde',
  templateUrl: './update-kiosk-jde.component.html'
})
export class UpdateKioskJdeComponent {
  @Output() completed: EventEmitter<void>

  public error: string
  public input: FormControl

  public constructor(
    private adminService: AdminService,
    private authService: AuthenticationService,
    private kioskServerService: KioskServerService
  ) {
    this.completed = new EventEmitter()
    this.input = new FormControl('', [Validators.required])
  }

  public onRegisterKiosk(): void {
    if (!this.input.valid) {
      return
    }
    this.adminService.findKioskByJDE(this.input.value).subscribe((kiosks: Kiosk[]) => {
      if (kiosks.length > 0) {
        this.error = null
        this.authService.registerKioskId(kiosks[0].id.toString()).subscribe(data => {
          this.kioskServerService.saveKioskConfig({ jde: data.jde_number.toString(), token: data.authorization.token.toString(), kioskId: data.id.toString() })
            .subscribe(
              next => {
                localStorage.setItem(KIOSK_JDE_KEY, data.jde_number.toString())
                localStorage.setItem(KIOSK_TOKEN_KEY, data.authorization.token.toString())
                localStorage.setItem(KIOSK_ID_KEY, data.id.toString())

                this.completed.emit()
                window.location.reload()
              },
              error => {
                this.completed.emit()
                window.location.reload()
              }
            )
        })
      } else {
        this.error = ERROR_MESSAGE
      }
    })
  }
}
