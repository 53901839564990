import { autoserialize, autoserializeAs } from 'cerialize'

export class KioskCommand {
  @autoserialize id: number
  @autoserializeAs('kiosk_id') kioskId: number
  @autoserializeAs('name') name: string
  @autoserializeAs('type') type: string
  @autoserializeAs('params') params: Object
  public constructor() { }
}
