<app-header (back)="onBack()" [canNavigateBack]="this.currentPage != 'operations'" [customBackNavigation]="true" [redBorder]="true" [showLanguage]="false"></app-header>
<div class="kiosk-app-admin">
  <div *ngIf="currentPage === 'operations'" class="d-flex flex-column justify-content-between operations pt-5">
    <div class="buttons d-flex flex-row justify-content-between pt-0 mb-5">
      <button (click)="callRotate()" class="btn-rug button d-flex align-items-center justify-content-center">FLIP SCREEN</button>
    </div>

    <div class="w-100 title pb-5 text-center">
      <h1>CHOOSE OPERATION</h1>
    </div>

    <div class="w-100 d-flex flex-column justify-content-between options">
      <button (click)="setCurrentPage('bay')" class="btn-rug confirm-button" id="bay-config-btn">BAY CONFIGURATION</button>
      <button (click)="setCurrentPage('kiosk')" class="btn-rug confirm-button" id="kiosk-config-btn">KIOSK CONFIGURATION</button>
      <button (click)="setCurrentPage('diagnostic')" class="btn-rug confirm-button" id="kiosk-diagnostic-btn">DIAGNOSTIC</button>
    </div>

    <button (click)="setLogOutDialog(true)" class="btn-rug confirm-button short mt-5">LOG OUT</button>
  </div>
  <app-bay-configuration (baySelected)="onSelectBay($event)" (newBay)="onNewBay()" *ngIf="currentPage === 'bay'"></app-bay-configuration>
  <app-configure-bay (return)="onBack()" *ngIf="currentPage === 'configure-bay'" [bayStatus]="currentBay"></app-configure-bay>
  <app-new-bay (return)="onBayConfiguration()" *ngIf="currentPage === 'new-bay'"></app-new-bay>
  <app-kiosk-configuration (updateKioskEnvironment)="setCurrentPage('kiosk_env')" (updateKioskJde)="setCurrentPage('kiosk_jde')" *ngIf="currentPage === 'kiosk'"></app-kiosk-configuration>
  <app-update-kiosk-jde (completed)="setCurrentPage('operations')" *ngIf="currentPage === 'kiosk_jde'"></app-update-kiosk-jde>
  <app-kiosk-diagnostic *ngIf="currentPage === 'diagnostic'"></app-kiosk-diagnostic>
  <div *ngIf="logOutDialog" class="dialog">
    <div class="dialog-box d-flex flex-column justify-content-between">
      <div class="title">
        ARE YOU SURE YOU WANT TO LOG OUT?
      </div>
      <div class="buttons d-flex w-100 justify-content-between">
        <button (click)="setLogOutDialog(false)" class="btn-rug button hollow">NO</button>
        <button (click)="onLogOut()" class="btn-rug button">YES</button>
      </div>
    </div>
  </div>
</div>
<img alt="path" class="decoration-path" src="assets/img/path.svg" />
<img alt="background" class="decoration-background" src="assets/img/bays-background.jpg" />
