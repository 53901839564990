import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Deserialize } from 'cerialize'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '@environment/environment'
import { AuthenticationService } from 'src/app/modules/authentication-module/authentication.service'
import { Bundle } from 'src/app/domain/bundle.model'

const BASE_URL_KEY = 'base_url'

@Injectable({
  providedIn: 'root'
})
export class BundleService {

  private baseUrl: string

  public constructor(
    private http: HttpClient,
    private auth: AuthenticationService
  ) {
    this.baseUrl = localStorage.getItem(BASE_URL_KEY)
  }

  public getAll(): Observable<Bundle[]> {
    return this.http.get(this.baseUrl + 'bundles?kiosk_id=' + this.auth.kioskId, { headers: this.auth.kioskAuthHeader }).pipe(
      map((bundle: Bundle[]) => {

        return Deserialize(bundle, Bundle)
      }
      ))
  }
}
