import { Component } from '@angular/core'
import { RoutingService } from '@shared/services'
import { IdleService } from '@shared/services/idle.service'
import { KioskServerService } from "@shared/services/kiosk-server.service"

@Component({
  selector: 'app-screen-saver',
  templateUrl: './screen-saver.component.html',
  styleUrls: ['./screen-saver.component.scss']
})
export class ScreenSaverComponent {

  public constructor(
    private idleService: IdleService,
    private routingService: RoutingService,
    private kioskServerService: KioskServerService,
  ) {
    setTimeout(function () {
      var video = document.querySelector('video')
      video.muted = true
      video.play()
    }, 1000)

    let _this = this
    setTimeout(function () {
      _this.kioskServerService.reload()
    }, 20000)
  }

  public onClick(): void {
    this.idleService.restart()
    this.routingService.navigateToSplashScreen()
  }
}
