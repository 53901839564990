import { EventEmitter, Injectable } from '@angular/core'
import { BaysService } from './bays.service'
import { StockService } from './stock.service'
import { RentalService } from '@shared/services/rental.service'
import { RoutingService } from '@shared/services'
import { AuthenticationService } from 'src/app/modules/authentication-module/authentication.service'
import { Rental } from 'src/app/domain/rental.model'
import { Bundle } from 'src/app/domain/bundle.model'
import { CartCoupon } from 'src/app/domain/cart-coupon.model'
import { RentalProduct } from 'src/app/domain/rental-product'
import { Reservation } from 'src/app/domain/reservation.model'
import { RentalDetails } from 'src/app/domain/rental-details.model'
import { PaymentDetails } from 'src/app/domain/payment-details.model'
import { BayTransactionType } from 'src/app/domain/bay-transaction.model'
import { ProductType, ProductGroup } from 'src/app/domain/product-type.model'
import {
  Coupon,
  CouponType,
  CouponTransactionType,
  CouponFactor
} from 'src/app/domain/coupon.model'

@Injectable({ providedIn: 'root' })
export class CartService {

  private rentalPrepared: boolean
  public loadingPrices: boolean
  private currentRental: Rental
  private externalReservation: Reservation
  private appliedCoupons: CartCoupon[]
  public paymentDetails: PaymentDetails
  public paymentFailed = new EventEmitter();
  public couponError = '';
  public get items(): RentalProduct[] { return this.currentRental.products || [] }
  public set items(values: RentalProduct[]) { this.currentRental.products = values }
  public get rentalId(): string { return this.currentRental.id }
  public set rentalId(value: string) { this.currentRental.id = value }
  public get rental(): Rental { return this.currentRental }
  public get reservation(): Reservation { return this.externalReservation }
  public get isEmpty(): boolean {
    return this.currentRental.isEmpty
  }
  public get loadingPrice(): boolean {
    return this.loadingPrices
  }
  public get isReservation(): boolean {
    return this.reservation != null
  }
  public get activeCoupon(): CartCoupon {
    const regularCoupons: CartCoupon[] = this.appliedCoupons.filter(coupon => coupon.type === CouponType.Regular)
    return (regularCoupons.length > 0) ? regularCoupons[0] : null
  }
  public get coupons(): CartCoupon[] {
    return this.appliedCoupons
  }
  public get couponCodes(): string[] {
    return this.appliedCoupons.map(coupon => coupon.code)
  }
  public get machines(): RentalProduct[] {
    return this.items.filter(item => item.type.productGroup === ProductGroup.Machines)
  }
  public get machinesPrice(): number {
    return this.machines.reduce((total, entry) => (total + entry.price), 0)
  }
  public get machineTypes(): ProductType[] {
    return this.machines.map((machine: RentalProduct) => machine.type)
  }
  public get attachments(): RentalProduct[] {
    return this.items.filter(item => item.type.productGroup === ProductGroup.Attachments)
  }
  public get solutions(): RentalProduct[] {
    return this.items.filter(item => item.type.productGroup === ProductGroup.Solutions)
  }
  public get solutionsPrice(): number {
    return this.solutions.reduce((total, entry) => (total + entry.price), 0)
  }
  public get progress(): number {
    let progress = 0
    for (const group in ProductGroup) {
      if (this.hasGroup(ProductGroup[group])) {
        progress++
      }
    }
    return (progress > 3) ? 3 : progress
  }

  public get couponErrorMsg() {
    return this.couponError
  }

  constructor(
    private baysService: BaysService,
    private stockService: StockService,
    private rentalService: RentalService,
    private routingService: RoutingService,
    private authService: AuthenticationService
  ) {
    this.restart()
  }

  public update(): void {

    this.loadingPrices = true
    this.stockService.assessCompatibility(this.machineTypes)
    if (!this.rental.id) {
      this.couponError = ""
      this.rentalService.create(this.rental).subscribe((rentalDetails: RentalDetails) => {
        this.rental.id = rentalDetails.rental.id
        this.paymentDetails = rentalDetails.paymentDetails
        this.loadingPrices = false
      }, error => {
        let couponErrorType = /coupon/gi
        let errorMessage = error || ''
        this.appliedCoupons = []
        this.rental.couponCodes = []

        if (typeof errorMessage === 'string' && couponErrorType.test(error)) {
          this.couponError = error
        }
      })
    } else {
      if (this.isEmpty) {
        this.paymentDetails = new PaymentDetails(0, 0)
      } else {
        this.couponError = ""
        this.rentalService.update(this.rental).subscribe((rentalDetails: RentalDetails) => {
          this.rental.id = rentalDetails.rental.id
          this.paymentDetails = rentalDetails.paymentDetails
          this.loadingPrices = false
        }, error => {
          let couponErrorType = /coupon/gi
          let errorMessage = error || ''
          this.appliedCoupons = []
          this.rental.couponCodes = []

          if (typeof errorMessage === 'string' && couponErrorType.test(errorMessage)) {
            this.couponError = errorMessage
          }
        })
      }
    }
  }

  public complete() {
    if (this.isReservation) {
      this.rentalService.changeReservation(this.rental).subscribe((reservation: Reservation) => {
        this.proceedWithTransaction(reservation)
      }, error => {
        console.log(error)
        this.paymentFailed.emit(error)
      })
    } else {
      if (this.rentalPrepared) {
        this.rentalService.publish(this.rental).subscribe((reservation: Reservation) => {
          if (Array.isArray(this.rental.products) && this.rental.products.length >= 1 && reservation.state === 'pending') {
            const findMachine = this.rental.products.find(e => e.type?.productGroup === 'machine')
            if (!findMachine) {
              this.rentalService.pickUp(reservation.id).subscribe(
                next => {
                },
                error => {
                })
            }
          }
          this.proceedWithTransaction(reservation)
        },
          error => {
            console.log(error)
            this.paymentFailed.emit(error)
          }
        )
      } else {
        this.rentalPrepared = true
        this.routingService.navigateToPayment()
      }
    }
  }

  private proceedWithTransaction(reservation: Reservation): void {
    this.baysService.createTransaction(BayTransactionType.Rent, reservation)
    this.restart()
    this.routingService.navigateToBays()
  }

  public quantity(productTypeId: number): number {
    const exisiting: RentalProduct[] = this.items.filter(rental => rental.type.id === productTypeId)
    return (exisiting.length === 0) ? 0 : exisiting[0].quantity
  }

  public hasGroup(group: ProductGroup): boolean {
    if (group === ProductGroup.Bundle) {
      return this.appliedCoupons.filter(coupon => coupon.type === CouponType.Bundle).length > 0
    }
    return this.items.filter(item => item.type.productGroup === group).length > 0
  }

  public add(item: RentalProduct, callUpdate: boolean = true): void {
    if (!this.stockService.available(item.type.id)) {
      return
    }

    this.stockService.changeQuantity(item.type.id, - item.quantity)
    const exisiting: RentalProduct[] = this.items.filter(rental => rental.type.id === item.type.id)

    if (item.type.productGroup !== ProductGroup.Machines && item.type.productGroup !== ProductGroup.Attachments && exisiting.length > 0) {
      exisiting[0].quantity += item.quantity
    } else {
      const findDuplicate = this.items.find(e => e.type.id === item.type.id)
      if (!findDuplicate) {
        if (item.type.productGroup === 'machine') {
          const machineExist = this.items.find(item => item.type.productGroup === 'machine')
          if (!machineExist) { //Only one machine per checkout allowed
            this.items.push(item)
          }
        }
        else {
          this.items.push(item)
        }


      }

    }
    if (callUpdate) {
      this.update()
    }
  }

  public addBundle(bundle: Bundle): boolean {
    for (const product of bundle.products) {
      if (product.quantity === 0 && product.productGroup !== ProductGroup.Solutions) {
        return false
      }
    }
    bundle.products.forEach(product => this.add(new RentalProduct(product), false))
    this.addCoupon(bundle.coupon)
    return true
  }

  public remove(productTypeId: number, quantity: number): void {
    const exisiting: RentalProduct[] = this.items.filter(rental => rental.type.id === productTypeId)
    if (exisiting.length !== 0) {
      exisiting[0].quantity -= quantity
      if (exisiting[0].quantity === 0) {
        this.removeAtIndex(this.items.indexOf(exisiting[0]))
      }
    }
  }

  public removeAtIndex(index: number): void {
    if (index < this.items.length && !this.items[index].reserved) {

      if (this.items[index].type.productGroup === 'machine') {
        this.stockService.changeQuantity(this.items[index].type.id, this.items[index].quantity)
        this.items.splice(index, 1)
        // this.items = [...this.items];

        const toolIndex = this.items.indexOf(this.items.find(item => item.type.productGroup === 'attachment'))
        if (toolIndex !== -1) {
          this.items.splice(toolIndex, 1)
        }
        this.checkCouponsEligible()
        this.update()
      }
      else {
        this.stockService.changeQuantity(this.items[index].type.id, this.items[index].quantity)
        this.items.splice(index, 1)
        // this.items = [...this.items];
        this.checkCouponsEligible()
        this.update()
      }


    }
  }

  public addCoupon(coupon: Coupon, requirements?: ProductType[]): void {
    const requirementIds: number[] = (requirements) ? requirements.map(productType => productType.id) : []
    // if (coupon.type === CouponType.Regular) {
    //   this.clearRegularCoupons()
    // }
    this.appliedCoupons = []
    this.appliedCoupons.push(new CartCoupon(coupon, requirementIds))
    this.syncRentalCoupons()
    // this.update()
  }

  public applyExternalReservation(reservation: Reservation): void {
    this.rental.id = reservation.id.toString()
    this.externalReservation = reservation
    this.stockService.prepareReservation(reservation)
    this.items = [...reservation.products]
    this.appliedCoupons = []
    this.items.forEach(item => {
      if (item.type.productGroup === ProductGroup.Machines) {
        item.reserved = true
      }
    })
    // reservation.coupons.forEach(coupon => this.addCoupon(coupon))
    // this.update()
  }

  public restart(): void {
    this.rentalPrepared = false
    this.paymentDetails = new PaymentDetails(0, 0)
    this.externalReservation = null
    this.currentRental = new Rental()
    this.currentRental.kioskId = this.authService.kioskId;;
    this.appliedCoupons = []
  }

  // private clearRegularCoupons(): void {
  //   this.appliedCoupons = this.appliedCoupons.filter(coupon => coupon.type !== CouponType.Regular)
  // }

  // private checkCouponsEligible(): void {
  //   this.appliedCoupons = this.appliedCoupons.filter(coupon => this.checkCouponEligible(coupon))
  // }

  private syncRentalCoupons(): void {
    this.rental.couponCodes = this.coupons.map((coupon: CartCoupon) => coupon.code)
    if (!this.isReservation) {
      this.update()
    }
  }

  // private checkCouponEligible(coupon: CartCoupon): boolean {
  //   let eligible = true
  //   coupon.requirements.forEach(productTypeId => {
  //     if (this.items.filter(
  //       item => item.type.id === productTypeId).length === 0) {
  //       eligible = false
  //     }
  //   })
  //   if (coupon.type === CouponType.Regular) {
  //     eligible = eligible && this.checkCouponTransactionEligible(coupon.coupon)
  //   }
  //   return eligible
  // }

  public checkCouponTransactionEligible(coupon: Coupon): boolean {
    const hasMachine = this.items.filter(item => item.type.productGroup === ProductGroup.Machines).length > 0
    const hasSolution = this.items.filter(item => item.type.productGroup === ProductGroup.Solutions).length > 0
    switch (coupon.transactionType) {
      case CouponTransactionType.Both:
        return hasMachine && hasSolution
      case CouponTransactionType.Machine:
        return (hasMachine && !hasSolution)
      case CouponTransactionType.Solution:
        return (hasSolution && !hasMachine)
      default:
        return true
    }
  }

  private clearRegularCoupons(): void {
    this.appliedCoupons = this.appliedCoupons.filter(coupon => coupon.type !== CouponType.Regular)

    this.syncRentalCoupons()
  }

  private clearBundleCoupons(): void {
    this.appliedCoupons = this.appliedCoupons.filter(coupon => coupon.type !== CouponType.Bundle)
    this.syncRentalCoupons()
  }

  private checkCouponsEligible(): void {
    this.appliedCoupons = this.appliedCoupons.filter(coupon => this.checkCouponEligible(coupon))
    this.syncRentalCoupons()
  }

  private checkCouponEligible(coupon: CartCoupon): boolean {
    let eligible = true

    if (coupon.requirements.length === 0 && coupon.type === CouponType.Bundle) {
      this.clearBundleCoupons()
      eligible = false
    }

    coupon.requirements.forEach(productTypeId => {
      if (this.items.filter(
        item => item.type.id === productTypeId).length === 0) {
        eligible = false
      }
    })
    if (coupon.type === CouponType.Regular) {
      eligible = eligible && this.checkCouponTransactionEligible(coupon.coupon)
    }
    return eligible
  }
}
