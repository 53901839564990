import { Injectable } from '@angular/core'
import { environment } from '@environment/environment'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { Product } from 'src/app/domain/product.model'
import { Deserialize } from 'cerialize'
import { ProductGroup, ProductType } from 'src/app/domain/product-type.model'
import { AuthenticationService } from 'src/app/modules/authentication-module/authentication.service'
import { Observable } from 'rxjs'

const BASE_URL_KEY = 'base_url'

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private baseUrl: string

  public constructor(
    private http: HttpClient,
    private auth: AuthenticationService
  ) {
    this.baseUrl = localStorage.getItem(BASE_URL_KEY)
  }

  public getAll(): Observable<Product[]> {
    return this.http.get(this.baseUrl + 'products').pipe(
      map((product: Product) => {
        return Deserialize(product, Product)
      }
      ))
  }

  public getBySerial(serial: string): Observable<Product[]> {
    return this.http.get(this.baseUrl + 'products?serial_number=' + serial, { headers: this.auth.adminAuthHeader }).pipe(
      map((product: Product[]) => {
        return Deserialize(product, Product)
      }
      ))
  }

  public getByProductType(productTypeId: number): Observable<Product[]> {
    return this.http.get(this.baseUrl + 'products?product_type_id=' + productTypeId,
      { headers: this.auth.adminAuthHeader }).pipe(
        map((product: Product[]) => {
          return Deserialize(product, Product)
        }
        ))
  }

  public createMachineInBay(serialNumber: string, productTypeId: string, bayId: string): Observable<Product> {
    return this.http.post(this.baseUrl + 'products/', {
      product_type_id: productTypeId,
      bay_id: bayId,
      serial_number: serialNumber,
      type: ProductGroup.Machines
    }, { headers: this.auth.adminAuthHeader }).pipe(
      map((product: Product[]) => {
        return Deserialize(product, Product)
      }
      ))
  }

  public getFeaturedProductType(): Observable<ProductType> {
    return new Observable(observer => {
      this.getTypes(ProductGroup.Machines, true).subscribe((types: ProductType[]) => {
        let featured: ProductType = types[0]
        types.forEach(product => {
          if (product.price < featured.price) {
            featured = product
          }
        })
        observer.next(featured)
        observer.complete()
      },
        error => observer.error(error))
    })
  }

  public getTypes(group?: string, kioskAvailable?: boolean): Observable<ProductType[]> {
    const kioskId: string = (this.auth.kioskId != null) ? 'kiosk_id=' + this.auth.kioskId : ''
    const params = (group) ? '?product_group=' + group + ((kioskAvailable) ? '&' + kioskId : '') : '?' + kioskId
    return this.http.get(this.baseUrl + 'product_types' + params, { headers: this.auth.kioskAuthHeader }).pipe(
      map((productType: ProductType) => {
        return Deserialize(productType, ProductType)
      }
      ))
  }

  public loadMachineToBay(productId: number, bayId: number): Observable<any> {
    return this.loadToBay(productId, bayId, 1)
  }

  public loadAttachmentsToBay(productTypeId: number, bayId: number, quantity: number): Observable<any> {
    return new Observable(observer => {
      this.getByProductType(productTypeId).subscribe(products => {
        if (products.length > 0) {
          this.loadToBay(products[0].id, bayId, quantity).subscribe(result => {
            observer.next(result)
            observer.complete()
          },
            error => observer.error(error))
        } else {
          observer.error('Product Type not found')
        }
      },
        error => observer.error(error))
    })
  }

  public unloadAttachmentFromBay(productTypeId: number, bayId: number): Observable<any> {
    return new Observable(observer => {
      this.getByProductType(productTypeId).subscribe(products => {
        if (products.length > 0) {
          this.unloadFromBay(products[0].id, bayId).subscribe(result => {
            observer.next(result)
            observer.complete()
          },
            error => observer.error(error))
        } else {
          observer.error('Product Type not found')
        }
      },
        error => observer.error(error))
    })
  }

  public loadToBay(productId: number, bayId: number, quantity: number): Observable<any> {
    return this.http.put(
      this.baseUrl + 'products/' + productId + '/assign',
      { bay_id: bayId, bay_quantity: quantity },
      { headers: this.auth.adminAuthHeader }
    ).pipe(
      map((productType: ProductType) => {
        return Deserialize(productType, ProductType)
      }
      ))
  }

  public unloadFromBay(productId: number, bayId: number): Observable<any> {
    return this.http.put(
      this.baseUrl + 'products/' + productId + '/unload',
      { bay_id: bayId },
      { headers: this.auth.adminAuthHeader }
    ).pipe(
      map((productType: ProductType) => {
        return Deserialize(productType, ProductType)
      }
      ))
  }

  public getRelatedProducts(machineId: number): Observable<ProductType[]> {
    const params = '?related_product_type_id=' + machineId.toString() + '&kiosk_id=' + this.auth.kioskId.toString() + '&product_group=attachment'
    return this.http.get(this.baseUrl + 'product_types' + params).pipe(
      map((productTypes: ProductType[]) => {
        return Deserialize(productTypes, ProductType)
      }
      ))
  }
  public getRelatedSolutions(machineId: number): Observable<ProductType[]> {
    const params = '?related_product_type_id=' + machineId.toString() + '&kiosk_id=' + this.auth.kioskId.toString() + '&product_group=solution'
    return this.http.get(this.baseUrl + 'product_types' + params).pipe(
      map((productTypes: ProductType[]) => {
        return Deserialize(productTypes, ProductType)
      }
      ))
  }
}
